import querystring from "query-string";
import { useEffect, useLayoutEffect } from "react";

export const getVideoIdFromYoutubeLink = (link = "") => {
  const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const match = link.match(regex);
  if (match) return match[1];
  return null;
};

export const chopString = (str, length) => (str.length > length ? `${str.substring(0, length)}...` : str);

export const copyToClipboard = (val) => {
  const el = document.createElement("textarea");
  el.value = val;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const cancelPropagation = (e) => {
  if (e) {
    e.stopPropagation();
    e.preventDefault();
  }
};

export const isRoleTicked = (i) => ["0", "4", "5", "6"].includes(String(i))

export const parseJsonOrNull = (str) => {
  try {
    return JSON.parse(str);
  } catch (_) {
    return null;
  }
};

export const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const hexToRgbA = (hex, alpha = 1) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join("")}`;
    /* eslint-disable-next-line no-bitwise */
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${alpha})`;
  }
  throw new Error("Bad Hex");
};

export const pushIf = (cond, el) => (cond ? [el] : []);

export const getBrowserLanguageNumber = () => {
  if (typeof navigator !== "undefined") {
    const browserLang = navigator.language || navigator.userLanguage || "";
    const languageCode = Number(browserLang.startsWith("es"));
    return languageCode;
  }
  return 1;
};
export const getClientsideHref = () => {
  if (typeof window !== "undefined") {
    return {
      host: window.location.origin,
      query: querystring.parse(window.location.search),
      href: window.location.href,
      hash: window.location.hash.substr(1),
    };
  }
  return {
    host: "",
    query: {

    },
    hash: "",
  };
};
