import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getBrowserLanguageNumber } from "utils";
import moment from "moment";
import es from "./locales/es.json";
import en from "./locales/en.json";
import "moment/locale/es";
import "moment/locale/en-au";

const mapCodeToDictionary = {
  0: en,
  1: es,
};
const I18nContext = createContext({
  t: () => {},
  language: "",
  setLanguage: () => {},
});

export const useTranslation = () => useContext(I18nContext);

export const I18nProvider = ({ children }) => {
  const [language, setLanguage] = useState(getBrowserLanguageNumber());
  const t = useCallback((key) => mapCodeToDictionary[language][key], [language]);
  const context = useMemo(() => ({ language, setLanguage, t }), [language, t]);

  useEffect(() => {
    if (language === 0) moment.locale("en-au");
    else moment.locale("es");
  }, [language]);

  return (
    <I18nContext.Provider value={context}>
      {children}
    </I18nContext.Provider>
  );
};

export const withI18n = (Component) => (props) => (
  <I18nProvider>
    <Component {...props} />
  </I18nProvider>
);
